import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/1080p_Series_CGI_List/Features_Menu/featuresCGI';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p Serie CGI List :: Features Menu' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1080p Serie CGI List :: Features Menu' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Features_Menu/' locationFR='/fr/1080p_Series_CGI_List/Features_Menu/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <IntroCards mdxType="IntroCards" />
    {/* * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/Email/">Email</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/FTP/">FTP</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/IR_Nightvision/">IR Nightvision</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/Manual_Recording/">Manual Recording</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/MQTT/">MQTT</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/PTZ/">PTZ</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/PTZ-Tour/">PTZ-Tour</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/SD_Card/">SD Card</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Features_Menu/Status_LEDs/">Status_LEDs</Link> */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      